import './App.css';
import '../src/input.css';
import Tabs from './components/Header';

function App() {
  return (
    <div className="App">
      <ul className="App-nav">
        <Tabs />
      </ul>
      <header className="App-header">
      
      <div className="bg-white" style={{backgroundColor: "transparent"}}>
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8" style={{backgroundColor: "transparent"}}>
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8" style={{backgroundColor: "transparent"}}>
            
          </div>
        </div>
      </div>
      </header>
    </div>
  );
}

export default App;
