import '../App.css';
import happy_crochet from '../imgs/crochet_happy.png';
import heart_crochet from '../imgs/crochet_heart.png';
import happy_bear from '../imgs/happy_bear.png';
import { Outlet, Link } from "react-router-dom";
import Tabs from "./Header";

const OurStory = () => {
    return (
      <>
        <div className="App">
            <ul className="App-nav"><Tabs /></ul>
            <header className="App-header">
            <h1 className="Page-header">Our Story</h1>
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                    <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-20">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img src={happy_crochet} alt="The Start of A New Journey" />
                                </div>
                                <div class="flip-card-back">
                                    <h3>Our Passion</h3>
                                    <p>Crocheting has become a passion for me, and I love the creative outlet it provides.</p>
                                    <p>I'm so glad I discovered crocheting, and I know I'll be crocheting for many years to come. It's a hobby that I'm truly passionate about, and it brings me a lot of joy.</p>
                                </div>
                            </div>
                        </div>
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img src={heart_crochet} alt="The Start of A New Journey" />
                                </div>
                                <div class="flip-card-back">
                                    <h1>Our Mission</h1>
                                    <p>Gifted Gal's mission is to bring a little more joy into your world.</p>
                                    <p>We love that guy</p>
                                </div>
                            </div>
                        </div>
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <div class="flip-card-front">
                                    <img src={happy_bear} alt="The Start of A New Journey" />
                                </div>
                                <div class="flip-card-back">
                                    <h1>John Doe</h1>
                                    <p>Architect & Engineer</p>
                                    <p>We love that guy</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
  
        <Outlet />
      </>
    )
  };
  
  export default OurStory;