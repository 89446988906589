import React from 'react';
import Tabs from './Header';

const Custom = () => {
    return (
        <div className="App">
            <ul className="App-nav">
                <Tabs />
            </ul>
            <header className="App-header">
                <h1 className="Page-header">Custom Requests</h1>
                    <div className="Custom-container">
                        <form name="CustomRequest" className="Custom-form">
                            <label for="FullName">Requestor's Full Name</label>
                            <input type="text" name="FullName" placeholder="Gifted Gal" id="FullName"></input>
                            <label for="Email">Requestor's Email</label>
                            <input type="text" name="Email" placeholder="Giftedgal0429@gmail.com" id="Email"></input>
                            <label for="Description">Amigurumi's Description</label>
                            <textarea className="Custom-description" name="Description" placeholder="A blue-eyed, pink bunny holding a red heart." id="Description"></textarea>
                            <label for="Size">Size</label>
                            <input type="text" name="Size" placeholder="Large" id="Size"></input>
                            <p className="Help-text">If you aren't sure, enter Mini, Small, Medium, or Large.</p>
                            <button className="Send-request">Send Request</button>
                        </form>
                    </div>
            </header>
        </div>
      );
};

export default Custom;