import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Navbar = styled.nav`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const li = styled.li`
  display: inline-block;
  padding: 0 1rem;
  cursor: pointer;
  float: left;
`;

const a = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 16px;
  text-decoration: none;
`;

const Tabs = () => {
  const tabs = [
    {
      name: 'Shop All',
      href: '/shop',
    },
    {
      name: 'New',
      href: '/new',
    },
    {
      name: 'Our Story',
      href: '/our-story'
    },
    {
      name: 'Custom Requests',
      href: '/custom-requests',
    },
    {
      name: 'Reviews',
      href: '/reviews',
    },
    {
      name: 'FAQ / Contact',
      href: '/faq',
    },
  ];

  return (
    <Header>
      <Navbar>
        {tabs.map((tab) => (
          <li key={tab.name}>
            <a href={tab.href}>{tab.name}</a>
          </li>
        ))}
      </Navbar>
    </Header>
  );
};

export default Tabs;