import "../App.css";
import happy_crochet from "../imgs/crochet_happy.png";
import heart_crochet from "../imgs/crochet_heart.png";
import happy_bear from "../imgs/happy_bear.png";
import { Outlet, Link } from "react-router-dom";
import Tabs from "../components/Header";

const FAQ = () => {
  return (
    <>
      <div className="App">
        <ul className="App-nav">
          <Tabs />
        </ul>
        <header className="App-header">
          <h1 className="Page-header">FAQ</h1>
          <div className="bg-white">
            <div className="UL-container">
                <ul className="Nav-list">
                    <li className="Question">What is the Return Policy?</li>
                    <li className="Answer">All sales are final. If you have any issues please contact us below.</li>
                    <li className="Question">How big are the Amigurumi (crocheted animals)?</li>
                    <li className="Answer">All Amigurumi will have sizing details on their product page. Please make sure to take note of the size as we do not accept returns.</li>
                    <li className="Question">I've ordered this Amigurumi by accident. Can you help me?</li>
                    <li className="Answer">If your Amigurumi hasn't shipped, you can contact us to exchange it for a different one. We do not offer refunds.</li>
                    <li className="Question">What makes Gifted Gal's Amigurumi special?</li>
                    <li className="Answer">All Amigurumi are by hand with Joy and Love. You get to name your Amigurumi and you'll receive a "Birth Certificate" with details about it too!</li>
                </ul>
            </div>
          </div>
        </header>
      </div>

      <Outlet />
    </>
  );
};

export default FAQ;
