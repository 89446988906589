import React from 'react';
import Tabs from './Header';
import MarshmellowThumbnail from '../imgs/marshmellow-thumbnail.jpg'
import TeddybearThumbnail from '../imgs/teddybear-thumbnail.jpg'
import DinosaurThumbnail from '../imgs/dinosaur-thumbnail.jpg'
import CakeThumbnail from '../imgs/cake-thumbnail.jpg';
import FlamingoThumbnail from '../imgs/flamingo-thumbnail.jpg';
import StingrayThumbnail from '../imgs/stingray-thumbnail.jpg';

const Products = () => {
   const products = [
        {
            id: 1,
            name: 'Mug & Mellow',
            href: 'product/mugnmellow',
            imageSrc: MarshmellowThumbnail,
            imageAlt: "Mug & Mellow. Adorable coffee cup with marshmellow.",
            price: '$35',
            color: 'Gray and Brown'
        },
        {
            id: 2,
            name: 'Ted E. Bear',
            href: 'product/teddybear',
            imageSrc: TeddybearThumbnail,
            imageAlt: "Ted E. Bear. A soft cuddly teddy bear",
            price: '$35',
            color: 'Brown'
        },
        {
            id: 3,
            name: 'Rawry The Dinosaur',
            href: 'product/dinosaur',
            imageSrc: DinosaurThumbnail,
            imageAlt: "Front of men's Basic Tee in black.",
            price: '$35',
            color: 'Green, Purple'
        },
        {
            id: 4,
            name: 'Cakey The Cake',
            href: 'product/cake',
            imageSrc: CakeThumbnail,
            imageAlt: "Front of men's Basic Tee in black.",
            price: '$35',
            color: 'Green, Purple'
        },
        {
            id: 5,
            name: 'Flam and Flamer',
            href: 'product/flamingos',
            imageSrc: FlamingoThumbnail,
            imageAlt: "Front of men's Basic Tee in black.",
            price: '$35',
            color: 'Green, Purple'
        },
        {
            id: 6,
            name: 'Stingy',
            href: 'product/stingray',
            imageSrc: StingrayThumbnail,
            imageAlt: "Front of men's Basic Tee in black.",
            price: '$35',
            color: 'Green, Purple'
        },
    ];

    return (
        <div className="App">
            <ul className="App-nav">
            <Tabs />
            </ul>
            <header className="App-header">
                <h1 className="Page-header">All Products</h1>
                <div className="Product-container">
                    {products.map((product) => (
                        <div key={product.id} className="Product">
                            <div className="Product-image aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                            <img
                                src={product.imageSrc}
                                alt={product.imageAlt}
                                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                            />
                            </div>
                            <div className="mt-4 flex justify-between">
                            <div>
                                <h3 className="text-sm text-gray-700">
                                <a href={product.href}>
                                    <span aria-hidden="true" className="inset-0" />
                                    {product.name}
                                </a>
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                            </div>
                            <p className="text-sm font-medium text-gray-900">{product.price}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </header>
        </div>
      );
};

export default Products;