import "../App.css";
import { Outlet } from "react-router-dom";
import Tabs from "../components/Header";

const New = () => {
  return (
    <>
      <div className="App">
        <ul className="App-nav">
          <Tabs />
        </ul>
        <header className="App-header">
          <h1 className="Page-header">What's New?</h1>
          
        </header>
      </div>

      <Outlet />
    </>
  );
};

export default New;
