import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import OurStory from './components/Story';
import Products from './components/Products';
import New from './components/New';
import Custom from './components/Custom';
import Reviews from './components/Review';
import FAQ from './components/FAQ'

export default function Home() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/shop" element={<Products />} />
        <Route path="/new" element={<New />} />
        <Route path="/custom-requests" element={<Custom />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </BrowserRouter>
  )
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Home />);