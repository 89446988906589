import "../App.css";
import { Outlet } from "react-router-dom";
import Tabs from "../components/Header";
import Heart from '../imgs/heart-2.jpg';

const Reviews = () => {
  return (
    <>
      <div className="App">
        <ul className="App-nav">
          <Tabs />
        </ul>
        <header className="App-header">
          <h1 className="Page-header">Reviews</h1>
          <button className="Review-button">Want to leave a review? Click here.</button>
          <div className="Review-container">
            <div className="Review-card">
                <img className="Review-heart" src={Heart}></img>
                <h5 className="Reviewer-name">Jessica</h5>
                <p className="Review-text">Review Text</p>
            </div>
            <div className="Review-card">
                <img className="Review-heart" src={Heart}></img>
                <h5 className="Reviewer-name">Jessica</h5>
                <p className="Review-text">Review Text</p>
            </div>
            <div className="Review-card">
                <img className="Review-heart" src={Heart}></img>
                <h5 className="Reviewer-name">Jessica</h5>
                <p className="Review-text">Review Text</p>
            </div>
            <div className="Review-card">
                <img className="Review-heart" src={Heart}></img>
                <h5 className="Reviewer-name">Jessica</h5>
                <p className="Review-text">Review Text</p>
            </div>
            <div className="Review-card">
                <img className="Review-heart" src={Heart}></img>
                <h5 className="Reviewer-name">Jessica</h5>
                <p className="Review-text">Review Text</p>
            </div>
          </div>
        </header>
      </div>

      <Outlet />
    </>
  );
};

export default Reviews;
